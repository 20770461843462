import { memo, useCallback } from "react";
import copy from "copy-to-clipboard";

import { Fab, IconButton, useMediaQuery } from "@mui/material";
import { Share, ShareOutlined } from "@mui/icons-material";

import FadingIcon from "ReusableComponents/FadingIcon";
import { useGeneral } from "contexts/GeneralContext";
import { navBreakpoint } from "utils/navUtils";

const ShareButton = () => {
  const isSmall = useMediaQuery(navBreakpoint);

  const { setSnackbar } = useGeneral();

  const handleOpen = useCallback(
    () =>
      setSnackbar({
        level: "success",
        content: "Link gekopieerd naar clipboard",
      }),
    [setSnackbar]
  );

  const handleCopy = useCallback(() => {
    copy(window.location) && handleOpen();
  }, [handleOpen]);

  return isSmall ? (
    <Fab size="small" onClick={handleCopy}>
      <FadingIcon icons={[ShareOutlined, Share]} />
    </Fab>
  ) : (
    <IconButton onClick={handleCopy}>
      <FadingIcon icons={[ShareOutlined, Share]} />
    </IconButton>
  );
};

export default memo(ShareButton);
