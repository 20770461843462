import { memo } from "react";

import PaneTitle from "Navigation/Components/PaneTitle";

import Vlieglijnen from "./Vlieglijnen";
import Transparency from "./Transparency";
import Styles from "./Styles";
import { CONTENT_OBJ } from "utils/contentUtils";
import Services from "./Services";
import Product from "./Product";
import Legend from "./Legend";
import { useParams } from "react-router-dom";
import { useData } from "contexts/DataContext";
import { useGeneral } from "contexts/GeneralContext";
import { Stack } from "@mui/material";

const IndividualLayer = ({ layer }) => {
  const { version, product, style: styleNumber } = useParams();
  const { dynamicStyle } = useGeneral();
  const data = useData();

  if (!layer) {
    return null;
  }
  let style = data[CONTENT_OBJ[version][product]].raster.styles[styleNumber];

  if (style.method === "dynamicStyle") {
    style = dynamicStyle;
  }

  return (
    <>
      <PaneTitle back="layers">{layer}</PaneTitle>

      <Stack p={1} px={2} gap={2}>
        <Product />

        <Vlieglijnen />

        <Transparency />

        <Styles />
        <Legend style={style} type={"presentationMode"} />
        <Services />
      </Stack>
    </>
  );
};

export default memo(IndividualLayer);
