import React from "react";

import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Box,
} from "@mui/material";

import { Close } from "@mui/icons-material";

const DialogTitle = ({
  id,
  title,
  close,
  onClose,
  classes,
  titleProps,
  className,
  children,
  color = "initial",
  ...props
}) => {
  const { className: titleClassName, ...restProps } = { ...titleProps };

  const closeFunc = close ?? onClose;

  return (
    <MuiDialogTitle
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        padding: 2,
        ...(color === "primary"
          ? (theme) => ({
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              boxShadow: theme.shadows[1],
            })
          : {}),
      }}
      className={className}
      {...props}
      component="div"
      id={id}
    >
      <Typography
        noWrap
        variant="h2"
        sx={{
          flexGrow: 1,
          lineHeight: 1.25,
        }}
        className={titleClassName}
        {...restProps}
      >
        {title || children}
      </Typography>
      {!!closeFunc && (
        <Box
          sx={(theme) => ({
            display: "flex",
            width: theme.spacing(3),
            height: theme.spacing(3),
            alignItems: "center",
            justifyContent: "center",
            color: "inherit",
          })}
        >
          <IconButton
            aria-label="close"
            onClick={closeFunc}
            size="large"
            color={color !== "initial" ? "inherit" : undefined}
          >
            <Close />
          </IconButton>
        </Box>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
