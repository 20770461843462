import { memo, useCallback } from "react";

import { Fab } from "@mui/material";
import { Layers, LayersOutlined } from "@mui/icons-material";

import { PANE_TYPES } from "Navigation/Panes";

import FadingIcon from "ReusableComponents/FadingIcon";

import useQueryNav from "hooks/useQueryNav";

import { usePaneFuncs } from "contexts/PaneContext";

const LayersButton = () => {
  const { pane, openPane } = usePaneFuncs();
  const { queryNav } = useQueryNav();

  const openLayers = useCallback(() => {
    queryNav({ a: null });
    openPane({ type: PANE_TYPES["layers"] });
  }, [openPane, queryNav]);

  return (
    <Fab
      color="tertiary"
      onClick={openLayers}
      sx={{
        position: { xs: "fixed", sm: "relative" },
        bottom: (theme) => ({ xs: theme.spacing(12), sm: 0 }),
        right: (theme) => ({ xs: theme.spacing(2), sm: 0 }),
        zIndex: 2,
      }}
    >
      <FadingIcon icons={[LayersOutlined, Layers]} in={!!pane} />
    </Fab>
  );
};

export default memo(LayersButton);
