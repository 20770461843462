import { memo } from "react";
import { useParams } from "react-router-dom";
import { CONTENT_OBJ } from "utils/contentUtils";
import copy from "copy-to-clipboard";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";

import { CopyAllOutlined } from "@mui/icons-material";
import { useGeneral } from "contexts/GeneralContext";
import useQueryNav from "hooks/useQueryNav";
import { QUERY_PARAMS_OBJ } from "utils/navUtils";
import { APP_URL } from "App";

const productServices = {
  DSM: ["wms", "wmts", "wcs"],
  DTM: ["wms", "wmts", "wcs"],
  vl: ["wfs", "features"],
  omhullen: ["wfs", "features"],
};

const Services = memo(() => {
  const { version, product } = useParams();
  const { searchParams } = useQueryNav();
  const { setSnackbar } = useGeneral();

  const copyLink = (s) => {
    const url = `${APP_URL}/ogc/${s}/${CONTENT_OBJ[version][product]}`;
    copy(url);
    setSnackbar({
      level: "success",
      content: "Link gekopieerd naar clipboard",
    });
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Services
      </Typography>
      <List disablePadding mt={-2}>
        {Object.entries(productServices)
          .filter(([key]) => key === product || key === searchParams?.ex)
          .map(([key, value], i) =>
            value.map((s, j) => (
              <>
                {i > 0 && j === 0 && (
                  <ListSubheader
                    sx={{ backgroundColor: "inherit" }}
                    disableSticky
                  >
                    {QUERY_PARAMS_OBJ[key]?.label}
                  </ListSubheader>
                )}
                <ListItem sx={{ p: 0 }} key={s}>
                  <Tooltip title={"Copy link"} arrow>
                    <ListItemButton
                      sx={{ mx: -2, px: 4 }}
                      onClick={() => copyLink(s)}
                    >
                      <ListItemText
                        primary={s}
                        secondary={`Kopieer ${s} link van ${version} - ${
                          QUERY_PARAMS_OBJ[key]?.label ?? product
                        }`}
                      />

                      <ListItemSecondaryAction>
                        <IconButton disableRipple>
                          <CopyAllOutlined />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </>
            ))
          )}
      </List>
    </Box>
  );
});

export default Services;
