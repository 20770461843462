const dayjs = require("dayjs");

const customDateParse = (d) => {
  const p1 = dayjs(d);
  const p2 = dayjs(
    d,
    ["DD/MM/YYYY", "DD-MM-YYYY", "YYYYMMDD", "YYYY-MM-DD"],
    "nl",
    true
  );

  return d?.length <= 4
    ? d
    : p1?.isValid()
    ? p1.format("L")
    : p2?.isValid()
    ? p2?.format("L")
    : d;
};

const formatHeight = (hoogte) =>
  isNaN(hoogte)
    ? "Geen Data"
    : `${hoogte > 0 ? "+" : ""}${(Math.round(hoogte * 100) / 100).toFixed(
        2
      )} m NAP`;

export { customDateParse, formatHeight };
