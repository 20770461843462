import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { renderToString } from "react-dom/server";

import L, { Icon } from "leaflet";
import { EllipsisVectorLayer, EllipsisRasterLayer } from "leaflet-ellipsis";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";

import { isMobile } from "react-device-detect";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { styleSjabloon } from "contexts/GeneralContext";
import { useAnalyse } from "contexts/AnalyseContext";
import { useData } from "contexts/DataContext";
import { useGeneral } from "contexts/GeneralContext";

import useParamNav from "hooks/useParamNav";
import useQueryNav from "hooks/useQueryNav";

import { CONTENT_OBJ } from "utils/contentUtils";
import { customDateParse, formatHeight } from "utils/valueParsers";
import { PARAM_ROUTES_OBJ, QUERY_PARAMS_OBJ } from "utils/navUtils";

import { manipulateCoords } from "./manipulateCoords";
import theme from "theme";
import { APP_URL } from "App";

export const ZIndices = {
  background: 1,
  elevation: 2,
  raster: 3,
  omhullen: 4,
  vlieglijnen: 5,
  kaartbladen: 6,
  flyTo: 7,
  draw: 8,
};

const maxBounds = [
  //south west
  [
    PARAM_ROUTES_OBJ?.y?.expectedValue?.min,
    PARAM_ROUTES_OBJ?.x?.expectedValue?.min,
  ],
  //north east
  [
    PARAM_ROUTES_OBJ?.y?.expectedValue?.max,
    PARAM_ROUTES_OBJ?.x?.expectedValue?.max,
  ],
];

const backgroundMap =
  "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=pastel&STYLE=default&FORMAT=image/png&TILEMATRIXSET=EPSG:28992&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}";
const Map = () => {
  const { flyTo, dynamicStyle, setDynamicStyle, setSnackbar } = useGeneral();
  const data = useData();
  const { version, product, style: styleNumber, x, y, z } = useParams();

  const [h, setH] = useState();
  const [d, setD] = useState();

  const { analyseType, setAnalyse, hover } = useAnalyse();
  const { paramNav } = useParamNav();
  const { searchParams } = useQueryNav();

  const [mapLoaded, setMapLoaded] = useState(false);
  const onlyOnce = useRef(false);
  const map = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (onlyOnce.current) return;
    onlyOnce.current = true;

    const position = manipulateCoords({
      lat: parseFloat(y),
      lng: parseFloat(x),
    });

    map.current = L.map("leafletmap", {
      center: L.latLng(position.lat, position.lng),
      zoom: z,
      minZoom: 2,
      maxZoom: PARAM_ROUTES_OBJ?.z?.expectedValue?.max,
      attributionControl: false,
      maxBounds: [
        [-200, -200],
        [200, 250],
      ],
      zoomControl: false,
    });

    const keys = Object.keys(ZIndices);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      map.current.createPane(k);
      map.current.getPane(k).style.zIndex = ZIndices[k];
    }

    //L.control.attribution({ prefix: "" }).addTo(map.current);

    setMapLoaded(true);
  }, [setAnalyse, x, y, z]);

  const onDrawn = useCallback(
    (e) => {
      if (drawControl.current) {
        drawControl.current.disable();
        drawControl.current = null;
      }
      let layer = e.layer;

      let feature = layer.toGeoJSON();

      const manipulatedFeature = manipulateCoords(null, feature, true);
      setAnalyse(manipulatedFeature);
    },
    [setAnalyse]
  );

  const oldDraw = useRef();

  useEffect(() => {
    if (!mapLoaded) return;

    if (map.current.listens(L.Draw.Event.CREATED) && oldDraw.current) {
      map.current.off(L.Draw.Event.CREATED, oldDraw.current);
    }

    oldDraw.current = onDrawn;
    map.current.on(L.Draw.Event.CREATED, onDrawn);
  }, [mapLoaded, onDrawn]);

  const urlUpdater = useRef();

  useEffect(() => {
    if (!mapLoaded) return;
    if (urlUpdater.current) {
      map.current.off("moveend", urlUpdater.current);
    }
    urlUpdater.current = () => {
      const center = map.current.getCenter();
      const zoom = map.current.getZoom();

      const manipulatedCenter = manipulateCoords(center, null, true);

      paramNav({
        z: zoom,
        x: Math.round(manipulatedCenter.lng * 100000) / 100000,
        y: Math.round(manipulatedCenter.lat * 100000) / 100000,
      });
    };
    map.current.on("moveend", urlUpdater.current);
  }, [paramNav, mapLoaded]);

  const prevPolyLine = useRef();
  const prevMarkerDraw = useRef();
  useEffect(() => {
    if (!mapLoaded) return;
    if (prevPolyLine.current) {
      map.current.removeLayer(prevPolyLine.current);
    }
    if (prevMarkerDraw.current) {
      map.current.removeLayer(prevMarkerDraw.current);
    }

    if (!searchParams.a) return;
    const feature = manipulateCoords(null, JSON.parse(searchParams.a));
    if (feature.geometry.type === "LineString") {
      prevPolyLine.current = L.geoJSON(feature, {
        pane: "draw",
        style: {
          color: "red",
        },
      }).addTo(map.current);
    } else if (feature.geometry.type === "Polygon") {
      prevPolyLine.current = L.geoJSON(feature, {
        pane: "draw",
        style: {
          color: "red",
        },
      }).addTo(map.current);
    } else {
      prevMarkerDraw.current = new L.marker(
        {
          lat: feature.geometry.coordinates[1],
          lng: feature.geometry.coordinates[0],
        },
        {
          pane: "draw",
          icon: new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          }),
        }
      );

      prevMarkerDraw.current.addTo(map.current);
    }
  }, [searchParams.a, mapLoaded]);

  const hoverMarker = useRef();
  useEffect(() => {
    if (!mapLoaded) return;
    if (hoverMarker.current) {
      map.current.removeLayer(hoverMarker.current);
    }

    if (hover && searchParams.a) {
      const manipulatedHover = manipulateCoords({
        lat: hover[1],
        lng: hover[0],
      });

      hoverMarker.current = new L.circleMarker(
        {
          lat: manipulatedHover.lat,
          lng: manipulatedHover.lng,
        },
        {
          pane: "draw",
          color: "red",
        }
      );

      hoverMarker.current.addTo(map.current);
    }
  }, [hover, searchParams.a, mapLoaded]);

  const drawControl = useRef();

  useEffect(() => {
    if (!mapLoaded) return;

    if (drawControl.current) {
      drawControl.current.disable();
    }

    if (analyseType) {
      if (analyseType === "line") {
        drawControl.current = new L.Draw.Polyline(map.current);
        drawControl.current.setOptions({
          allowIntersection: false,
          showLength: false,
          metric: ["km", "m"],
          pane: "draw",
          zIndexOffset: 5000,
        });
      } else if (analyseType === "polygon") {
        drawControl.current = new L.Draw.Polygon(map.current);
        drawControl.current.setOptions({
          allowIntersection: false,
          showLength: false,
          metric: ["km", "m"],
          pane: "draw",
          zIndexOffset: 5000,
        });
      } else if (analyseType === "point") {
        drawControl.current = new L.Draw.Marker(map.current, {
          icon: new Icon({
            iconUrl: markerIconPng,
            pane: "draw",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          }),
        });
      }

      drawControl.current.enable();
    }
  }, [mapLoaded, analyseType]);

  const prevMarker = useRef();
  useEffect(() => {
    if (!mapLoaded) return;
    if (prevMarker.current) {
      map.current.removeLayer(prevMarker.current);
    }
    if (!flyTo) return;
    if (
      !(
        flyTo.latLng.lat >= maxBounds[0][0] &&
        flyTo.latLng.lat <= maxBounds[1][0] &&
        flyTo.latLng.lng >= maxBounds[0][1] &&
        flyTo.latLng.lng <= maxBounds[1][1]
      )
    ) {
      setSnackbar({
        content: "Locatie ligt buiten het bereik van de kaart",
        level: "error",
      });
      return;
    }

    if (flyTo.type === "point") {
      const flyLocation = manipulateCoords(flyTo.latLng);

      map.current.flyTo([flyLocation.lat, flyLocation.lng], 16);
      prevMarker.current = new L.marker(flyLocation, {
        pane: "flyTo",
        icon: new Icon({
          iconUrl: markerIconPng,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        }),
      });

      prevMarker.current.addTo(map.current);
    }
  }, [flyTo, mapLoaded, setSnackbar]);

  const prevOmhullen = useRef();
  useEffect(() => {
    if (!mapLoaded || isMobile) return;
    if (prevOmhullen.current) {
      map.current.removeLayer(prevOmhullen.current);
    }
    const layer = data[CONTENT_OBJ?.[version]?.["omhullen"]];
    if (!layer) return;
    const timestamp = layer.vector.timestamps.find(
      (t) => !t.trashed && t.status === "active"
    );
    const style = layer.vector.styles[0];
    style.parameters.alpha = 0;
    style.parameters.width = 0;

    prevOmhullen.current = new EllipsisVectorLayer({
      pathId: layer.id,
      timestampId: timestamp.id,
      style: style,
      pane: "omhullen",
      loadAll: true,
      manipulateCoords: (f) => manipulateCoords(null, f),
      onFeatureHover: (f, e) => {
        const relevantProperty = f.properties[style.parameters.popupProperty];
        setD(relevantProperty);
      },
    }).addTo(map.current);
  }, [version, mapLoaded, data]);

  const prevF = useRef();
  const prevOtherF = useRef();
  const loading = useRef(false);
  useEffect(() => {
    if (!mapLoaded) return;

    if (prevF.current) {
      map.current.off("moveend", prevF.current);
    }

    if (prevOtherF.current) {
      map.current.off("movestart", prevOtherF.current);
    }

    const layer = data[CONTENT_OBJ?.[version]?.[product]];
    if (!layer) return;
    const timestamp = layer.raster.timestamps.find(
      (t) => !t.trashed && t.status === "active"
    );
    const style = layer.raster.styles[styleNumber];
    if (style.method === "dynamicStyle") {
      prevOtherF.current = () => {
        if (prevLayer.current) {
          map.current.removeLayer(prevLayer.current);
        }
      };

      prevF.current = () => {
        if (loading.current) return;
        loading.current = true;

        const bounds = map.current.getBounds();

        const feature = {
          type: "Feature",
          geometry: {
            coordinates: [
              [
                [bounds._southWest.lng, bounds._southWest.lat],
                [bounds._southWest.lng, bounds._northEast.lat],
                [bounds._northEast.lng, bounds._northEast.lat],
                [bounds._northEast.lng, bounds._southWest.lat],
                [bounds._southWest.lng, bounds._southWest.lat],
              ],
            ],
            type: "Polygon",
          },
        };

        const manipulatedFeature = manipulateCoords(null, feature, true);
        const geometry = manipulatedFeature.geometry;

        fetch(
          `${APP_URL}/path/${
            layer.id
          }/raster/timestamp/analyse?returnType=all&timestampIds=["${
            timestamp.id
          }"]&geometry=${JSON.stringify(geometry)}&approximate=true`
        )
          .then((res) => res.json())
          .then((data) => {
            let newStyle;
            if (data[0].hasData) {
              let arr = data[0].result[0].values;

              const L = styleSjabloon.parameters.transitionPoints.length;
              newStyle = {
                ...styleSjabloon,
                parameters: { ...styleSjabloon.parameters },
              };

              arr = arr.sort(function (a, b) {
                return a - b;
              });

              const usedValues = [];
              const epsilon = 0.1;
              newStyle.parameters.transitionPoints =
                styleSjabloon.parameters.transitionPoints.map((x, i) => {
                  const p = Math.round((arr.length * i) / L);
                  const y = { ...x };
                  let newValue = arr[p];
                  while (usedValues.includes(newValue)) {
                    newValue = newValue + epsilon;
                  }
                  usedValues.push(newValue);
                  y.value = newValue;

                  return y;
                });
            } else {
              newStyle = { ...styleSjabloon };
            }

            setDynamicStyle(newStyle);
            loading.current = false;
          })
          .catch((err) => console.error(err));
      };
      prevF.current();
      map.current.on("moveend", prevF.current);
      map.current.on("movestart", prevOtherF.current);
    }
  }, [mapLoaded, data, product, version, styleNumber, setDynamicStyle]);

  const onderlaag = useRef();
  useEffect(() => {
    if (!mapLoaded) return;
    if (onderlaag.current) {
      map.current.removeLayer(onderlaag.current);
    }
    if (!searchParams.ol) {
      onderlaag.current = L.tileLayer(backgroundMap, {
        pane: "background",
        maxNativeZoom: 13,
        maxZoom: PARAM_ROUTES_OBJ?.z?.expectedValue?.max,
        minNativeZoom: 0,
        noWrap: true,
      });
      onderlaag.current.addTo(map.current);
    }
  }, [searchParams.ol, mapLoaded]);

  const prevLayer = useRef();
  const prevAltitude = useRef();
  const altitudeHover = useRef();

  useEffect(() => {
    if (
      data[CONTENT_OBJ?.[version]?.[product]]?.raster?.styles[styleNumber]
        ?.method !== "dynamicStyle"
    ) {
      setDynamicStyle(null);
    }
  }, [data, product, setDynamicStyle, styleNumber, version]);

  useEffect(() => {
    if (!mapLoaded) return;

    const layer = data[CONTENT_OBJ?.[version]?.[product]];
    if (!layer) return;

    const style = layer.raster.styles[styleNumber];
    const timestamp = layer.raster.timestamps.find(
      (t) => !t.trashed && t.status === "active"
    );

    if (prevLayer.current) {
      map.current.removeLayer(prevLayer.current);
    }

    if (prevAltitude.current) {
      map.current.removeLayer(prevAltitude.current);
    }
    if (altitudeHover.current) {
      map.current.off("mousemove", altitudeHover.current);
    }
    if (style.method === "dynamicStyle" && !dynamicStyle) return;
    prevLayer.current = new EllipsisRasterLayer({
      pathId: layer.id,
      timestampId: timestamp.id,
      epsg: 28992,
      maxZoom: PARAM_ROUTES_OBJ?.z?.expectedValue?.max,
      noWrap: true,
      pane: "raster",
      maxNativeZoom: 13,
      style: style.method === "dynamicStyle" ? dynamicStyle : style.id,
      opacity: 1,
    });
    prevLayer.current.addTo(map.current);
    if (!isMobile) {
      prevAltitude.current = new EllipsisRasterLayer({
        pathId: layer.id,
        timestampId: timestamp.id,
        epsg: 28992,
        noWrap: true,
        maxNativeZoom: 13,
        maxZoom: PARAM_ROUTES_OBJ?.z?.expectedValue?.max,
        pane: "elevation",
        style: terrainRgbStyle,
        opacity: 0,
      });
      prevAltitude.current.addTo(map.current);
      altitudeHover.current = function (event) {
        var a = prevAltitude.current.getColor(event.latlng);
        if (!a) {
          setH(NaN);
          return;
        } else if (a[3] < 255) {
          setH(NaN);
        } else {
          const height = -10000 + (a[0] * 256 * 256 + a[1] * 256 + a[2]) * 0.1;

          setH(height);
        }
      };
      map.current.on("mousemove", altitudeHover.current);
    }
  }, [version, product, styleNumber, mapLoaded, data, dynamicStyle]);

  useEffect(() => {
    map.current.getPane("raster").style.opacity =
      searchParams?.t || searchParams?.t === 0
        ? searchParams.t
        : QUERY_PARAMS_OBJ?.t?.defaultValue;
  }, [searchParams?.t]);

  const prevKb = useRef();
  useEffect(() => {
    if (!mapLoaded) return;

    if (prevKb.current) {
      map.current.removeLayer(prevKb.current);
    }

    if (searchParams?.ex !== "kb") return;

    const layer = data["a9d410ad-a2f6-404c-948a-fdf6b43e77a6"];
    if (!layer) return;

    const timestamp = layer.vector.timestamps.find(
      (t) => !t.trashed && t.status === "active"
    );
    prevKb.current = new EllipsisVectorLayer({
      pathId: layer.id,
      timestampId: timestamp.id,
      loadAll: true,
      manipulateCoords: (f) => manipulateCoords(null, f),
      pane: "kaartbladen",
      onFeatureClick: (f, e) =>
        L.popup({ maxHeight: 200 })
          .setLatLng(e.latlng)
          .setContent(makeContent(f))
          .openOn(map.current),
    }).addTo(map.current);
  }, [mapLoaded, searchParams?.ex, data]);

  const prevVl = useRef();
  useEffect(() => {
    if (!mapLoaded) return;
    if (prevVl.current) {
      map.current.removeLayer(prevVl.current);
      map.current.closePopup();
    }
    if (searchParams?.ex !== "vl") return;

    const layer = data[CONTENT_OBJ?.[version]?.["vl"]];

    if (!layer) return;

    const timestamp = layer.vector.timestamps.find(
      (t) => !t.trashed && t.status === "active"
    );

    prevVl.current = new EllipsisVectorLayer({
      pathId: layer.id,
      timestampId: timestamp.id,
      loadAll: true,
      manipulateCoords: (f) => manipulateCoords(null, f),
      pane: "vlieglijnen",
      onFeatureClick: (f, e) =>
        L.popup({ maxHeight: 200 })
          .setLatLng(e.latlng)
          .setContent(
            makeContent(
              f,
              layer?.vector?.styles?.[0]?.parameters?.popupProperty
            )
          )
          .openOn(map.current),
    }).addTo(map.current);
  }, [mapLoaded, version, data, searchParams?.ex]);

  useEffect(() => {
    document.title = `${version} - ${product}`;
  }, [product, version]);

  const timeout = useRef();

  const resize = useCallback(() => {
    const resetTimeout = () => {
      clearTimeout(timeout.current);
      timeout.current = null;
    };

    if (timeout.current) {
      resetTimeout();
    }

    timeout.current = setTimeout(() => {
      map?.current?.invalidateSize();
    }, 200);
  }, []);

  const resizeObserver = useMemo(() => new ResizeObserver(resize), [resize]);

  useEffect(() => {
    resizeObserver.observe(containerRef.current);
    return resizeObserver.unobserve(containerRef.current);
  }, [resizeObserver]);

  return (
    <Box flexGrow={1} sx={{ overflow: "hidden", "z-index": 1 }}>
      {!isMobile && (
        <Paper
          sx={(theme) => ({
            position: "fixed",
            zIndex: 2,
            p: 2,
            bottom: theme.spacing(2),
            right: theme.spacing(2),

            [theme.breakpoints.down("sm")]: { display: "none" },
          })}
        >
          <Typography textAlign="center">
            {version} - {product}
          </Typography>
          {searchParams.ex ? (
            <>{flexRow(QUERY_PARAMS_OBJ?.[searchParams.ex]?.label, "Actief")}</>
          ) : isNaN(h) ? (
            flexRow(undefined, "Geen data")
          ) : (
            <>
              {flexRow("Hoogte", formatHeight(h))}
              {flexRow("Datum", customDateParse(d))}
            </>
          )}
        </Paper>
      )}
      <Box
        id="leafletmap"
        ref={containerRef}
        sx={{
          height: "100%",
          width: "100%",
          zIndex: 1,

          "& .leaflet-popup-content": {
            overflowX: "hidden",
            marginTop: 3,
            marginRight: 0,
            marginBottom: 0,
            paddingRight: 2,
            paddingBottom: 2,
          },
          "& .leaflet-omhullen-pane path": { cursor: "inherit" },
        }}
      />
    </Box>
  );
};
export default Map;

const flexRow = (first, second) => (
  <Stack flexDirection="row" gap={1}>
    {first && <Typography>{first}:</Typography>}
    {second && (
      <Typography flexGrow={1} align="right" sx={{ minWidth: 115 }}>
        {second}
      </Typography>
    )}
  </Stack>
);

const makeContent = (f, popupProperty) => {
  const skip = ["compiledStyle", "color", "id", "userId", "radius"];

  const content = Object.keys(f.properties)?.map(
    (key) =>
      !skip.includes(key) && (
        <ListItem style={{ marginTop: theme.spacing(2) }}>
          <ListItemText
            primary={key}
            secondary={
              validURL(f.properties[key]) ? (
                <a
                  href={f.properties[key]}
                  style={{ overflowWrap: "anywhere" }}
                >
                  {f.properties[key]}
                </a>
              ) : !!popupProperty && key === popupProperty ? (
                customDateParse(f.properties[key])
              ) : (
                f.properties[key]
              )
            }
            primaryTypographyProps={{
              component: "span",
              style: {
                display: "block",
                ...theme.typography.overline,
                lineHeight: 1.5,
                overflowWrap: "anywhere",
              },
              variant: "overline",
            }}
            secondaryTypographyProps={{
              component: "span",
              style: {
                display: "block",
                ...theme.typography.body1,
                overflowWrap: "anywhere",
              },
              variant: "body1",
            }}
          />
        </ListItem>
      )
  );

  return renderToString(
    <List style={{ padding: 0, marginTop: theme.spacing(-2) }}>{content}</List>
  );
};

function validURL(str) {
  return typeof str === "string" && str.includes("https://");
}

const terrainRgbStyle = {
  method: "terrainRgb",
  parameters: { alpha: 1, offset: 0, bandNumber: 1, invert: false },
};
