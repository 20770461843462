import { createTheme, darken, responsiveFontSizes } from "@mui/material";

const capitalise = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const createButtonStyle =
  (color = "primary") =>
  ({ theme }) => ({
    backgroundColor: theme.palette[color].container,
    color: theme.palette[color].onContainer,
    "&:hover": {
      backgroundColor: darken(
        theme.palette[color].container,
        theme.palette.action.hoverOpacity
      ),
    },
  });

const SURFACE_COLORS = {
  lowest: "#FFFFFF",
  low: "#FFF1EC",
  main: "#FCEAE4",
  high: "#F6E4DE",
  highest: "#F1DFD9",
  text: "#231A16",
};

const PAPER_VARIANTS = Object.keys(SURFACE_COLORS)
  ?.filter((c) => c !== "text")
  ?.map((k) => ({
    props: { variant: "colored" + (k !== "main" ? capitalise(k) : "") },
    style: ({ theme }) => ({
      backgroundColor: theme.palette.surface[k],
      color: theme.palette.surface.text,
    }),
  }));

const theme = createTheme({
  components: {
    MuiFab: {
      defaultProps: { variant: "rounded", size: "medium", color: "primary" },
      styleOverrides: {
        sizeLarge: ({ theme }) => ({
          height: theme.spacing(12),
          width: theme.spacing(12),
        }),
        primary: createButtonStyle("primary"),
        secondary: createButtonStyle("secondary"),
        tertiary: createButtonStyle("tertiary"),
      },
      variants: [
        {
          props: { variant: "rounded" },
          style: ({ theme }) => ({
            borderRadius: theme.spacing(1),
          }),
        },
      ],
    },
    MuiPaper: {
      defaultProps: { variant: "colored" },
      variants: PAPER_VARIANTS,
    },
    MuiBottomNavigationAction: {
      styleOverrides: { root: ({ theme }) => ({ gap: theme.spacing(1.5) }) },
    },
  },
  typography: { h1: {}, h2: { fontSize: "1.5rem", lineHeight: 1.5 } },
});

export default responsiveFontSizes(
  createTheme(theme, {
    palette: {
      primary: {
        main: "#8E4D30",
        container: "#FFDBCE",
        onContainer: "#370E00",
      },
      secondary: {
        main: "#77574B",
        container: "#FFDBCE",
        onContainer: "#2C160D",
      },
      tertiary: theme.palette.augmentColor({
        color: {
          main: "#685F30",
          container: "#F0E3A8",
          onContainer: "#211B00",
        },
        name: "tertiary",
      }),
      surface: SURFACE_COLORS,
    },
  })
);
