import useQueryNav from "hooks/useQueryNav";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePaneFuncs } from "./PaneContext";
import { PANE_TYPES } from "Navigation/Panes";

const analyseContext = createContext();

export const AnalyseProvider = ({ children }) => {
  const [analyseType, setAnalyseType] = useState();
  const [hover, setHover] = useState();
  const { queryNav, searchParams } = useQueryNav();
  const { openPane, pane } = usePaneFuncs();
  // const [analyseCoords, setAnalyseCoords] = useState();

  const setAnalyse = useCallback(
    (GEOJSON) => {
      setAnalyseType(undefined);
      queryNav({ a: GEOJSON });
    },
    [queryNav]
  );

  useEffect(() => {
    if (!searchParams.a) return;
    openPane({ type: PANE_TYPES["analyse"] }, true);
  }, [searchParams, openPane, pane.type]);

  const handleSetAnalyseType = useCallback(
    (setter) => {
      queryNav({ a: null });
      setAnalyseType(setter);
    },
    [queryNav]
  );

  const value = useMemo(
    () => ({
      analyseType,
      setAnalyseType: handleSetAnalyseType,
      hover,
      setHover,
      setAnalyse,
    }),
    [analyseType, handleSetAnalyseType, setAnalyse, hover, setHover]
  );

  return (
    <analyseContext.Provider value={value}>{children}</analyseContext.Provider>
  );
};

export const useAnalyse = () => {
  const context = useContext(analyseContext);

  if (context === undefined) {
    throw new Error("useAnalyse must be used within a AnalyseProvider");
  }
  return context;
};
