import { memo } from "react";

import {
  BottomNavigation,
  Box,
  Drawer,
  Stack,
  useMediaQuery,
} from "@mui/material";

import Panes, { PANES } from "./Panes";

import AnalyseButton from "./Sidebar/AnalyseButton";
import HelpButton from "./Sidebar/HelpButton";
import ShareButton from "./Sidebar/ShareButton";
import SearchButton from "./Sidebar/SearchButton";

import LayersButton from "./Sidebar/LayersButton";
import LocationButton from "./Sidebar/LocationButton";

import { navBreakpoint } from "utils/navUtils";
import { ZIndices } from "Map/Map";

export const DRAWER_WIDTH = 80;
export const SIDE_PANE_WIDTH = 400;

const Navigation = () => {
  const isSmall = useMediaQuery(navBreakpoint);

  return (
    <>
      {!isSmall && (
        <Drawer
          variant="permanent"
          sx={{
            width: DRAWER_WIDTH,
            height: "100%",
            position: "relative",
            zIndex: Math.max(...Object.values(ZIndices)) + PANES?.length + 1,
          }}
          PaperProps={{ sx: { position: "static" } }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={2}
          >
            <img
              src="/logo.svg"
              alt="AHN logo"
              fetchPriority="high"
              loading="eager"
            />
          </Box>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            flexGrow={1}
            py={1}
            pb={3}
          >
            <LayersButton />

            <Stack alignItems="center" gap={2}>
              <SearchButton />

              <AnalyseButton />

              <ShareButton />

              <HelpButton />

              <LocationButton />
            </Stack>
          </Stack>
        </Drawer>
      )}

      <Panes />

      {isSmall && (
        <>
          <LayersButton />

          <Stack
            gap={2}
            sx={(theme) => ({
              position: "fixed",
              top: theme.spacing(2),
              right: theme.spacing(2),
              zIndex: 2,
            })}
          >
            <LocationButton />

            <ShareButton />
          </Stack>
          <BottomNavigation
            sx={(theme) => ({
              height: theme.spacing(10),
              backgroundColor: theme.palette.surface.main,
            })}
            showLabels
          >
            <SearchButton />

            <AnalyseButton />

            <HelpButton />
          </BottomNavigation>
        </>
      )}
    </>
  );
};
export default memo(Navigation);
