import { memo, useCallback } from "react";

import { Box, Slider, Typography } from "@mui/material";

import useQueryNav from "hooks/useQueryNav";

import { QUERY_PARAMS_OBJ, valueParser } from "utils/navUtils";

const Transparency = memo(() => {
  const type = "t";

  const { searchParams, queryNav } = useQueryNav();
  const { label, defaultValue, expectedValue } = QUERY_PARAMS_OBJ?.[type];

  const handleSliderCommit = useCallback(
    (e, value) => queryNav({ [type]: value }),
    [queryNav]
  );

  const inputValue = searchParams[type];

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        {label}
      </Typography>
      <Slider
        value={valueParser({
          inputValue,
          defaultValue,
          expectedValue,
        })}
        min={0}
        max={expectedValue?.max}
        step={0.1}
        valueLabelDisplay="auto"
        onChange={handleSliderCommit}
        onChangeCommitted={handleSliderCommit}
      />
    </Box>
  );
});

export default Transparency;
