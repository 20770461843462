import { memo } from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Box,
  ListItemButton,
} from "@mui/material";

import { QUERY_PARAMS_OBJ } from "utils/navUtils";

import useQueryNav from "hooks/useQueryNav";

const Vlieglijnen = memo(() => {
  const { searchParams, queryNav } = useQueryNav();

  const layer = QUERY_PARAMS_OBJ?.["vl"];
  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Extra lagen
      </Typography>

      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          sx={{ mx: -2 }}
          onClick={() => {
            queryNav({ ex: searchParams?.["ex"] === "vl" ? null : "vl" });
          }}
        >
          <ListItemIcon>
            <Checkbox checked={searchParams?.["ex"] === "vl"} disableRipple />
          </ListItemIcon>
          <ListItemText primary={layer?.label} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
});

export default Vlieglijnen;
