import {
  BottomNavigationAction,
  Fab,
  IconButton,
  Popover,
  Stack,
  useMediaQuery,
} from "@mui/material";
import {
  Colorize,
  ColorizeOutlined,
  Room,
  Timeline,
  CropFree,
} from "@mui/icons-material";

import FadingIcon from "ReusableComponents/FadingIcon";
import { useCallback, useState } from "react";
import { useAnalyse } from "contexts/AnalyseContext";
import { usePaneFuncs } from "contexts/PaneContext";
import { useGeneral } from "contexts/GeneralContext";
import { navBreakpoint } from "utils/navUtils";

const POSITIONING = {
  small: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  },
  large: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  },
};

const ACTIONS = [
  { type: "point", Icon: Room },
  { type: "line", Icon: Timeline },
  { type: "polygon", Icon: CropFree },
];

const AnalyseButton = (props) => {
  const isSmall = useMediaQuery(navBreakpoint);

  const { setSnackbar } = useGeneral();
  const [open, setOpen] = useState();
  const { closePane } = usePaneFuncs();
  const { setAnalyseType, setAnalyse } = useAnalyse();

  const swapOpen = useCallback(
    (e) => {
      setAnalyse(null);
      closePane();

      setOpen((old) => (!old ? e?.currentTarget : undefined));
    },
    [closePane, setAnalyse]
  );

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClick = useCallback(
    (type) => {
      setSnackbar({ level: "success", content: `Draw a ${type} on the map` });

      setAnalyseType(type);
      handleClose();
    },
    [handleClose, setAnalyseType, setSnackbar]
  );

  return (
    <>
      {!isSmall ? (
        <IconButton onClick={swapOpen}>
          <FadingIcon icons={[ColorizeOutlined, Colorize]} />
        </IconButton>
      ) : (
        <BottomNavigationAction
          {...props}
          label="Analyseer"
          icon={<Colorize />}
          onClick={swapOpen}
        />
      )}

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        slotProps={{
          // root: { sx: { zIndex: 1 } },
          paper: {
            elevation: 0,
            sx: { p: 2, ml: { xs: 0, sm: 2 }, backgroundColor: "unset" },
          },
        }}
        {...POSITIONING[isSmall ? "small" : "large"]}
      >
        <Stack gap={2} flexDirection={{ xs: "row", sm: "column" }}>
          {ACTIONS?.map(({ type, Icon }) => (
            <Fab key={type} size="small" onClick={() => handleClick(type)}>
              <Icon />
            </Fab>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default AnalyseButton;
