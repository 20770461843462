import { Box, Fade } from "@mui/material";
import { memo } from "react";

const FadingIcon = ({ icons, in: on, fontSize }) => {
  const [Outlined, Filled] = [...icons];

  return (
    <Box display="flex" position="relative">
      <Fade in={!on}>
        <Outlined {...{ fontSize }} />
      </Fade>
      <Fade
        in={!!on}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Filled {...{ fontSize }} />
      </Fade>
    </Box>
  );
};

export default memo(FadingIcon);
