import { useCallback, useMemo } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { PARAM_ROUTES, valueParser } from "utils/navUtils";

const useParamNav = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const paramsToPath = useCallback(
    (input = params) => {
      const route =
        "/" +
        PARAM_ROUTES?.map((d) =>
          valueParser({
            inputValue: input ? input?.[d?.route] : d?.defaultValue,
            ...d,
          })
        ).join("/") +
        search;

      return {
        route,
        isChanged:
          encodeURI(route) !== pathname + search && route !== pathname + search,
      };
    },
    [params, pathname, search]
  );

  const paramNav = useCallback(
    (newValue = {}, navigateOpts = {}) => {
      const { route, isChanged } = paramsToPath({ ...params, ...newValue });
      isChanged && navigate(route, navigateOpts);
    },
    [navigate, params, paramsToPath]
  );

  const output = useMemo(
    () => ({ paramsToPath, paramNav }),
    [paramNav, paramsToPath]
  );

  return output;
};

export default useParamNav;
