import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";

import { usePaneFuncs } from "../../contexts/PaneContext";
import { memo, useCallback } from "react";

const PaneTitle = ({ back, closeCb, secondary, children }) => {
  const { openPane, closePane } = usePaneFuncs();

  const handleClosePane = useCallback(() => {
    closePane();
    if (typeof closeCb === "function") closeCb();
  }, [closeCb, closePane]);

  return (
    <ListItem
      sx={{ position: "sticky", zIndex: 2, top: 0, backgroundColor: "inherit" }}
      component="div"
      secondaryAction={
        <IconButton onClick={handleClosePane} edge="end">
          <Close />
        </IconButton>
      }
    >
      {back && (
        <ListItemIcon>
          <IconButton onClick={() => openPane({ type: back })}>
            <ArrowBack />
          </IconButton>
        </ListItemIcon>
      )}
      <ListItemText primary={children} secondary={secondary} />
    </ListItem>
  );
};

export default memo(PaneTitle);
