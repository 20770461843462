import { lazy } from "react";
import { CONTENT_OBJ } from "./contentUtils";

export const navBreakpoint = (theme) => theme.breakpoints.down("sm");

export const PRODUCT_OBJ = {
  DSM: "Digital Surface Model",
  DTM: "Digital Terrain Model",
};

export const PARAM_ROUTES = [
  {
    route: "version",
    defaultValue: "AHN4",
    expectedValue: { values: Object.keys(CONTENT_OBJ) },
  },
  {
    route: "product",
    defaultValue: Object.keys(PRODUCT_OBJ)[1],
    expectedValue: { values: Object.keys(PRODUCT_OBJ) },
  },
  {
    route: "style",
    defaultValue: 0,
    expectedValue: { min: 0, max: 2 },
  },
  { route: "x", defaultValue: "6.5465", expectedValue: { min: 0, max: 9 } },
  {
    route: "y",
    defaultValue: "52.26738",
    expectedValue: { min: 46, max: 55 },
  },
  { route: "z", defaultValue: "3", expectedValue: { min: 2, max: 15 } },
];

export const PARAM_ROUTES_OBJ = PARAM_ROUTES.reduce(
  (pv, { route, ...cv }) => ({ ...pv, [route]: { ...cv } }),
  {}
);

export const QUERY_PARAMS = [
  {
    key: "ol",
    label: "Onderlaag",
    defaultValue: true,
    expectedValue: { type: "bool" },
  },
  { key: "ex", expectedValue: { values: ["vl", "kb"] } },
  {
    key: "vl",
    label: "Vlieglijnen",
  },
  {
    key: "kb",
    label: "Kaartbladen",
  },
  {
    key: "t",
    label: "Transparantie",
    defaultValue: 0.8,
    expectedValue: { min: 0.1, max: 1 },
  },
  // { key: "q" },
  { key: "a", expectedValue: { type: "feature" } },
];

export const QUERY_PARAMS_OBJ = QUERY_PARAMS.reduce(
  (pv, { key, ...cv }) => ({ ...pv, [key]: { ...cv } }),
  {}
);

export const valueParser = ({ inputValue, defaultValue, expectedValue }) => {
  if (inputValue === null && defaultValue !== undefined) {
    return defaultValue;
  }

  if (
    inputValue !== undefined &&
    defaultValue === undefined &&
    expectedValue === undefined
  ) {
    return inputValue;
  }

  if (expectedValue?.values && expectedValue?.values.includes(inputValue)) {
    return inputValue;
  } else if (
    expectedValue?.type === "bool" &&
    [true, false, "true", "false"]?.includes(inputValue)
  ) {
    return inputValue === "true" || inputValue === true ? true : false;
  } else if (
    expectedValue?.min !== undefined &&
    expectedValue?.max !== undefined
  ) {
    const parsed = Number(inputValue);

    if (!isNaN(parsed)) {
      const clamped = Math.min(
        Math.max(inputValue, expectedValue?.min),
        expectedValue?.max
      );

      return clamped !== parsed ? clamped : parsed;
    }
  } else if (expectedValue?.type === "feature") {
    var value;

    try {
      value =
        typeof inputValue === "object"
          ? JSON.stringify(inputValue)
          : JSON.parse(inputValue);
    } catch (err) {
      console.warn(err);
    }

    if (value) return value;
  }

  if (defaultValue !== undefined) {
    return defaultValue;
  }

  return null;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const RETRY_IMPORT_TIMES = 2;
const RETRY_IMPORT_DELAY = 1000 * 3;

export const retryableLazy = (lazyImport, name) => {
  return lazy(async () => {
    let component = null;
    let counter = 0;
    let error = new Set();

    while (component === null && counter < RETRY_IMPORT_TIMES) {
      counter += 1;
      try {
        component = await lazyImport();
      } catch (err) {
        error.add(err);
        await sleep(RETRY_IMPORT_DELAY);
      }
    }

    if (component == null) {
      console.error("Import failed, app possibly offline", lazyImport);
    }

    return name ? { default: component[name] } : component;
  });
};
