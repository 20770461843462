import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { QUERY_PARAMS_OBJ, valueParser } from "utils/navUtils";

const useQueryNav = () => {
  const [preSearchParams, setSearchParams] = useSearchParams();

  const searchParams = useMemo(
    () => Object.fromEntries(preSearchParams?.entries()),
    [preSearchParams]
  );

  const queryNav = useCallback(
    (params, options = {}) =>
      setSearchParams((qps) => {
        Object.entries(params)?.forEach(([key, value]) => {
          const qp = QUERY_PARAMS_OBJ?.[key];

          const parsed = valueParser({
            inputValue: value,
            ...qp,
          });

          const remove =
            (qp?.expectedValue?.type !== "bool" && !value && value !== 0) ||
            parsed === qp?.defaultValue;

          if (remove) {
            qps.delete(key);
          } else if (parsed !== null) {
            qps?.set(key, parsed);
          } else {
            console.warn(
              "Unsupported Query param",
              value,
              "expected",
              QUERY_PARAMS_OBJ?.[key]?.expectedValue
            );
          }
        });

        return qps;
      }),
    [setSearchParams]
  );
  const output = useMemo(
    () => ({ searchParams, queryNav }),
    [queryNav, searchParams]
  );

  return output;
};

export default useQueryNav;
