import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useParamNav from "hooks/useParamNav";

import App from "App";

const ParseRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { paramsToPath } = useParamNav();

  const { isChanged, route } = useMemo(() => paramsToPath(), [paramsToPath]);

  useEffect(() => {
    if (isChanged) {
      console.warn(
        "Unknown Route:",
        location?.pathname + location?.search,
        "redirecting to default:",
        route
      );

      navigate(route, { replace: true });
    }
  }, [isChanged, location?.pathname, location?.search, navigate, route]);

  return !isChanged && <App />;
};

export default ParseRoute;
