import { memo } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useGeneral } from "contexts/GeneralContext";

const CustomSnackBar = () => {
  const { snackbar, setSnackbar } = useGeneral();

  return (
    <Snackbar
      open={!!snackbar}
      autoHideDuration={6000}
      onClose={() => {
        setSnackbar(false);
      }}
    >
      <Alert severity={snackbar?.level} variant="filled" sx={{ width: "100%" }}>
        {snackbar?.content}
      </Alert>
    </Snackbar>
  );
};

export default memo(CustomSnackBar);
