import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const paneFuncs = createContext();

export const PaneProvider = ({ children }) => {
  const [pane, setPane] = useState(false);
  const openPane = useCallback((input, noBack) => {
    setPane((old) => {
      const newPane =
        noBack || !old?.type || old?.type !== input?.type ? input : false;
      return newPane;
    });
  }, []);
  const closePane = useCallback(() => {
    setPane(false);
  }, []);

  const value = useMemo(
    () => ({ pane, openPane, closePane }),
    [closePane, openPane, pane]
  );

  return <paneFuncs.Provider value={value}>{children}</paneFuncs.Provider>;
};

export const usePaneFuncs = () => {
  const context = useContext(paneFuncs);

  if (context === undefined) {
    throw new Error("usePaneFuncs must be used within a PaneProvider");
  }
  return context;
};
