import { memo, useCallback, useEffect, useRef, useState } from "react";

import {
  BottomNavigationAction,
  Fade,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close, Search, SearchOutlined } from "@mui/icons-material";

import FadingIcon from "ReusableComponents/FadingIcon";

import { usePaneFuncs } from "contexts/PaneContext";
import { useGeneral } from "contexts/GeneralContext";
import { useAnalyse } from "contexts/AnalyseContext";

import { navBreakpoint } from "utils/navUtils";
import Proj4 from "proj4";

const POSITIONING = {
  small: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  },
  large: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  },
};

const RD_BOUNDS = [
  [482.06, 306602.42],
  [284182.97, 637049.52],
];

const SearchButton = (props) => {
  const isSmall = useMediaQuery(navBreakpoint);

  const [open, setOpen] = useState();
  const { closePane } = usePaneFuncs();
  const { setAnalyse } = useAnalyse();
  const { setFlyTo } = useGeneral();
  const theme = useTheme();

  const swapOpen = useCallback(
    (e) => {
      closePane();
      setAnalyse(null);
      setOpen((old) => (!old ? e?.currentTarget : undefined));
    },
    [closePane, setAnalyse]
  );

  const handleClose = useCallback(() => {
    setFlyTo(null);
    setOpen(false);
  }, [setFlyTo]);

  return (
    <>
      {!isSmall ? (
        <Fade
          appear={false}
          in={!open}
          timeout={{ enter: theme.transitions.duration.complex }}
        >
          <IconButton onClick={swapOpen}>
            <FadingIcon icons={[SearchOutlined, Search]} in={open} />
          </IconButton>
        </Fade>
      ) : (
        <BottomNavigationAction
          {...props}
          label="Zoek"
          icon={<Search />}
          onClick={swapOpen}
        />
      )}

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        slotProps={{ paper: { sx: { borderRadius: 12, ml: -1.5 } } }}
        {...POSITIONING[isSmall ? "small" : "large"]}
      >
        <CustomTextfield handleClose={handleClose} />
      </Popover>
    </>
  );
};

export default memo(SearchButton);

const CustomTextfield = ({ handleClose }) => {
  const inputRef = useRef();
  const { setFlyTo, setSnackbar } = useGeneral();

  const [text, setText] = useState("");

  const handleTextChange = useCallback((e) => setText(e?.target?.value), []);

  const handleSearch = useCallback(() => {
    try {
      const split = text?.split(",");

      if (split?.length !== 2) {
        throw new Error("No coord format");
      }

      const coords = split?.map((s) => {
        const parsed = parseFloat(s?.replaceAll(" ", ""));

        if (isNaN(parsed)) {
          throw new Error("No coord");
        }

        return parsed;
      });

      if (
        coords
          .map((c, i) => c >= RD_BOUNDS[i][0] && c <= RD_BOUNDS[i][1])
          .includes(false)
      ) {
        throw new Error("Out of bounds, check other option");
      }

      const repr = Proj4("EPSG:28992", Proj4.WGS84, coords);

      setFlyTo({
        type: "point",
        latLng: {
          lat: repr[1],
          lng: repr[0],
        },
      });
    } catch (e) {
      console.error(e);

      let request1 = `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=pk.eyJ1IjoiZGFhbjE5OTEiLCJhIjoiY2tjb3h5OHFpMHB3YTMzcGJ3d2hrajVwZSJ9.BSZbQtdYXaM1YA6hVDP_yg`;

      fetch(request1)
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data.features.length === 0) {
            setSnackbar({
              level: "error",
              content: "Geen locatie gevonden voor deze naam",
            });
            return;
          }
          setFlyTo({
            type: "point",
            latLng: {
              lat: data.features[0].center[1],
              lng: data.features[0].center[0],
            },
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [setFlyTo, setSnackbar, text]);

  const handleEnter = useCallback(
    (ev) => {
      if (ev.key === "Enter") {
        ev.preventDefault();
        handleSearch();
      }
    },
    [handleSearch]
  );

  useEffect(() => inputRef.current.focus(), []);

  return (
    <TextField
      inputRef={inputRef}
      value={text}
      onChange={handleTextChange}
      onKeyDown={handleEnter}
      placeholder="Vul hier je zoekterm in"
      InputProps={{
        sx: { borderRadius: 12 },
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSearch}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
