import { memo, Suspense } from "react";

import { CircularProgress, Drawer } from "@mui/material";

import Layers from "./Panes/Layers/Layers";
import IndividualLayer from "./Panes/IndividualLayer/IndividualLayer";

import { usePaneFuncs } from "contexts/PaneContext";

import { DRAWER_WIDTH, SIDE_PANE_WIDTH } from "./Navigation";
import { ZIndices } from "Map/Map";
import { retryableLazy } from "utils/navUtils";

const Analyse = retryableLazy(() => import("./Panes/Analyse/Analyse"));

export const PANE_TYPES = {
  layers: "layers",
  individualLayer: "individualLayer",
  analyse: "analyse",
};

export const PANES = [
  { type: PANE_TYPES["layers"], Component: Layers },
  { type: PANE_TYPES["individualLayer"], Component: IndividualLayer },
  { type: PANE_TYPES["analyse"], Component: Analyse },
];

const Panes = () => {
  return (
    <>
      {PANES?.map((p, i) => (
        <Pane
          key={p?.type}
          {...p}
          zIndex={Math.max(...Object.values(ZIndices)) + i}
        />
      ))}
    </>
  );
};

export default Panes;

const Pane = memo(({ Component, type, zIndex }) => {
  const { pane } = usePaneFuncs();
  const { type: paneType, ...props } = { ...pane };

  return (
    <Drawer
      open={paneType === type}
      variant="persistent"
      sx={{ position: "relative", zIndex }}
      PaperProps={{
        variant: "coloredLow",
        sx: {
          left: { xs: 0, sm: DRAWER_WIDTH },
          width: SIDE_PANE_WIDTH,
          maxWidth: { xs: "100vw", sm: `calc(100vw - ${DRAWER_WIDTH}px)` },
          maxHeight: (theme) => ({
            xs: `calc(100vh - ${theme.spacing(10)})`,
            sm: `100vh`,
          }),
          overflowX: "hidden",
        },
      }}
    >
      <Suspense fallback={<CircularProgress />}>
        {<Component {...props} />}
      </Suspense>
    </Drawer>
  );
});
