import { memo, useCallback, useState } from "react";

import {
  BottomNavigationAction,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Help, HelpOutline } from "@mui/icons-material";

import FadingIcon from "ReusableComponents/FadingIcon";
import DialogTitle from "ReusableComponents/DialogTitle";
import { navBreakpoint } from "utils/navUtils";

const HelpButton = memo((props) => {
  const isSmall = useMediaQuery(navBreakpoint);

  const [open, setOpen] = useState(false);

  const swapOpen = useCallback(() => setOpen((old) => !old), []);

  const closeDialog = useCallback(() => setOpen(false), []);

  return (
    <>
      {!isSmall ? (
        <IconButton onClick={swapOpen}>
          <FadingIcon icons={[HelpOutline, Help]} in={open} />
        </IconButton>
      ) : (
        <BottomNavigationAction
          {...props}
          label="Help"
          icon={<Help />}
          onClick={swapOpen}
        />
      )}
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle onClose={closeDialog}>Help</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            Het Actueel Hoogtebestand Nederland (AHN) is een bestand met
            gedetailleerde en nauwkeurige hoogtegegevens voor heel Nederland.
            Voor heel Nederland is van elke vierkante meter bekend wat de hoogte
            is.
          </DialogContentText>
          <DialogContentText>
            De waterschappen, provincies en Rijkswaterstaat laten het AHN maken
            voor hun dagelijks werk, met name voor waterbeheer en
            waterkeringbeheer, maar ook voor andere toepassingen wordt het AHN
            gebruikt.
          </DialogContentText>
        </DialogContent>
        {false && (
          <DialogActions>
            <a href="https://app.ellipsis-drive.com/">
              Link naar instructie Video
            </a>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
});
export default HelpButton;
