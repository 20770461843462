import { memo, Suspense, useEffect } from "react";

import { Box, Skeleton, ThemeProvider } from "@mui/material";
import theme from "theme";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/nl";

import { AnalyseProvider } from "contexts/AnalyseContext";
import { DataProvider } from "contexts/DataContext";
import { PaneProvider } from "contexts/PaneContext";
import { GeneralProvider } from "contexts/GeneralContext";

import CustomSnackBar from "CustomSnackBar";

import Navigation from "Navigation/Navigation";
import { retryableLazy } from "utils/navUtils";

const Map = retryableLazy(() => import("Map/Map"));

const App = () => {
  useEffect(() => {
    dayjs.locale("nl");
    dayjs.extend(localizedFormat);
    dayjs.extend(customParseFormat);
  }, []);

  useEffect(() => {
    new Image().src = "/logo.svg";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="App"
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        <GeneralProvider>
          <DataProvider>
            <PaneProvider>
              <AnalyseProvider>
                <Navigation />

                <Suspense
                  fallback={
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      sx={{ flexGrow: 1, height: "initial" }}
                    />
                  }
                >
                  <Map />
                </Suspense>

                <CustomSnackBar />
              </AnalyseProvider>
            </PaneProvider>
          </DataProvider>
        </GeneralProvider>
      </Box>
    </ThemeProvider>
  );
};

export default memo(App);

export const APP_URL = "https://api.ellipsis-drive.com/v3";
